<template>
    <div v-if="counselor" class="project_volunteer" @click="onGoVolunteer">
        <div class="arrow"></div>
        <div class="content">
            <div class="user_title">
                此项目已得到{{ counselor.name }}爱心认证 请您多多帮助～
            </div>
            <div class="user_avatar">
                <img v-if="volunteerAvatar" v-lazy="volunteerAvatar" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import saUtils from 'sensors-data-utils';
const default_logo = require('./img/default-logo.png');

export default {
    name: 'projectVolunteer',
    data() {
        return {
            default_logo,
        };
    },

    computed: {
        ...mapState(['counselor']),

        volunteerAvatar() {
            return this.counselor.photo || this.default_logo;
        },

        volunteerPhone() {
            if (!this.counselor.mobile) return '';
            return `tel:${this.counselor.mobile}`;
        },
    },

    methods: {
        onGoVolunteer() {
            // saUtils.click('Button_Volunteer');
            // this.$router.push({
            //     path: '/fund/volunteer/detail',
            //     query: { mobile: this.counselor.mobile }
            // });
        },
    },
};
</script>

<style lang="less" scoped>
.project_volunteer {
    
    margin-top: -2px;
    background: #ebf7fd;
    border-radius: 4px;
    padding: 9px 21px;
    text-align: left;
    position: relative;
    .arrow{
        width: 14px;
        height: 14px;
        transform: rotate(45deg);
        position: absolute;
        left: 50px;
        top: -7px;
        background-color: #ebf7fd;

    }
    .content {
        display: flex;
        align-items: flex-start;
        position: relative;
        justify-content: flex-start;
    }
}


.user_avatar {
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
    border-radius: 4px;
    overflow: hidden;
    
    background: #fff;
    img {
        width: 100%;
        height: 100%;
        display: block;
    }
}

.user_title {
    font-size: 14px;
    color: #285E98;
    overflow: hidden;
    margin-right: 30px;

    .nickname {
        font-size: 15px;
        font-weight: 500;
        color: #333;
        line-height: 1;
        display: block;
        margin-bottom: 6px;
        max-width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.tel_btn {
    width: 90px;
    height: 30px;
    background: url('./img/tel_btn.png') no-repeat center;
    background-size: 100%;
}

.desc {
    padding: 0 12% 0 47px;
    font-size: 12px;
    color: #999;
    position: relative;
    &::after {
        content: '';
        display: block;
        height: 12px;
        width: 12px;
        background: url('./img/arrow-rt.png') no-repeat center;
        background-size: cover;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}

@media screen and (max-width: 340px) {
    .user_title {
        max-width: 120px;
        .nickname {
            font-size: 14px;
        }
    }

    .fund_tag {
        font-size: 10px;
    }
    .tel_btn {
        transform: scale(0.9);
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 1) {
    .user_title {
        .nickname {
            max-width: 160px;
        }
    }
}
</style>
